// import global styles
import './styles/index.scss';
import { externalLinks } from './scripts/external-links';
import { lazyLoad } from './scripts/image-lazy-load';
import { accordion } from './scripts/accordion';
import { loadMore, LoadMoreYears, LoadMoreReviews } from './scripts/loadmoreposts';
import { cookieBannerFunct } from './scripts/cookiebanner';
import { handleAnalyticsEvents } from './scripts/analytics';
import { abTest, showControl } from './scripts/abtest';
import { blogToc } from './scripts/blog-toc';
import { personalizedCTABanner } from './scripts/cta-banner';
import { personalizationHeroBanner } from './scripts/personalization-banner';
import { highlightText } from './scripts/highlighttext';
import { addUTMParam } from './scripts/addUTMParam';
// this module will be deferred

export default function init() {
  
  function onLoad() {
    externalLinks();
    lazyLoad();
    accordion();
    personalizationHeroBanner();
    personalizedCTABanner();
    loadMore();
    LoadMoreYears();
    cookieBannerFunct();
    handleAnalyticsEvents();
    LoadMoreReviews();
    blogToc();
    highlightText();
    addUTMParam();
  }

  if (document.readyState !== 'loading') {
    onLoad();
  } else {
    document.addEventListener('DOMContentLoaded', onLoad);
  }
  window.experimentCallback = function(){
    clearTimeout(experimentTimeOut);
    abTest(teal.data.experiments.experiment.active_experiments);
    console.log('---- clear timeout ----');
  };

  if(window.teal && teal.data && teal.data.experiments && teal.data.experiments.experiment && teal.data.experiments.experiment.active_experiments){
    abTest(teal.data.experiments.experiment.active_experiments);
    
  }else{
    document.addEventListener('experiment_ready', experimentCallback );
    window.experimentTimeOut = setTimeout(function(){
      if(window.teal && teal.data && teal.data.experiments && teal.data.experiments.experiment && teal.data.experiments.experiment.active_experiments){
        abTest(teal.data.experiments.experiment.active_experiments);
        
      }else{
        document.dispatchEvent(new CustomEvent("experiment_timeout",{"bubbles":true,"detail": {}}));
        document.removeEventListener('experiment_ready', experimentCallback);
        showControl();
        console.log('----show control----');
      }
    },1600);
  }
}

init();