export function addUTMParam(){

    let allLinks = document.querySelectorAll('a[href^="https://apply.oportun.com"], a[href^="https://aplica.oportun.com"], a[href^="https://offers.oportun.com"]');
    let allClassLinks = document.querySelectorAll('.visitor_id');
     // change href reference to a class selector
    if(!allLinks || !allClassLinks){
        return;
    }
    
    allLinks.forEach(elem => {
        if( typeof utag_data.tealium_visitor_id !== 'undefined' || utag_data.tealium_visitor_id !== null ){
            elem.href = elem.href+((elem.href.slice(-1) == '/') ? '' :'/' )+'?visitor_id='+utag_data.tealium_visitor_id;
        }
    });
    
    allClassLinks.forEach(elem => {
        if( typeof utag_data.tealium_visitor_id !== 'undefined' || utag_data.tealium_visitor_id !== null ){
            elem.href = elem.href+((elem.href.slice(-1) == '/') ? '' :'/' )+'?visitor_id='+utag_data.tealium_visitor_id;
        }
    });

}