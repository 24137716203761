export function lazyLoad() {
  const nodes = document.querySelectorAll('img.lazy');
  const onload = (e) => {
    e.target.removeEventListener('load', onload);
  };

  for (const node of nodes) {
    node.setAttribute('loading', 'lazy');
    node.setAttribute('srcset', node.dataset.srcset);
    node.setAttribute('src', node.dataset.src);

    node.addEventListener('load', onload);
  }
}
