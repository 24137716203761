export function blogToc(){
    
    let isABlog = document.querySelectorAll('.single-content');
    
    if(isABlog.length != 0){

        const headersHtwo   = querySelectorFrom('.content-wrap h2');
        const headersHthree = querySelectorFrom('.content-wrap h3');
        const listElements  = querySelectorFrom('.content-wrap ul');

        if(headersHtwo.length){linkTitlesToTOC(headersHtwo,listElements);}
        if(headersHthree.length){linkTitlesToTOC(headersHthree,listElements);}
    }

    function querySelectorFrom(selector){
        
        var all;
        if(selector != '.content-wrap ul'){
            all = document.querySelectorAll(selector);
        }else{
         
           all = document.querySelector(selector);
           all = all.querySelectorAll('li');
        }
        
        var temp = [];
        all.forEach(item => {
          if(temp.indexOf(item) === -1){
            temp.push(item);
          }
        });
        
        return all;
    }


    function string_to_slug(str) {
        str = str.replace(/^\s+|\s+$/g, ''); // trim
        str = str.toLowerCase();
    
        // remove accents, swap ñ for n, etc
        var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
        var to   = "aaaaeeeeiiiioooouuuunc------";
        for (var i=0, l=from.length ; i<l ; i++) {
            str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
        }

        str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
            .replace(/\s+/g, '-') // collapse whitespace and replace by -
            .replace(/-+/g, '-'); // collapse dashes

        return str;
    }

    function linkTitlesToTOC(titles,list){
        titles.forEach(eachHeader => { 
            
        list.forEach(listitem => {
                const textLen = listitem.innerText.split(" ");
                if( textLen.length == 1){
                    if( checkIfPartialMatches( eachHeader.innerText, listitem.innerText, 1) ){
                        let tmpSlug = string_to_slug(eachHeader.innerHTML);
                        eachHeader.setAttribute("id", tmpSlug);
                        listitem.innerHTML = '<a href="#'+tmpSlug+'">'+listitem.innerHTML+'</a>';
                    }
                }else {
                    if( checkIfPartialMatches( eachHeader.innerText, listitem.innerText, 3) ){
                        let tmpSlug = string_to_slug(eachHeader.innerHTML);
                        eachHeader.setAttribute("id", tmpSlug);
                        listitem.innerHTML = '<a href="#'+tmpSlug+'">'+listitem.innerHTML+'</a>';
                    }
                }
            });
        });
    }
    
    // I notice that not always the text between li and h2 or h3 matches 100%. (typos at times)
    // So, I made this to match when 3 or more words matches to take it as a valid
    function checkIfPartialMatches(str1, str2, partialMatchAccuracy = 3){
        str1 = str1.split(" ");
        str2 = str2.split(" ");
        
        let matches = 0;
        str2.forEach((text, key) => {
            if (str1[key] == str2[key]) {
                matches++;
            }
        });
        if (matches >= partialMatchAccuracy) {
            return true;
        } else {
            return false;
        }
    }
}