export function cookieBannerFunct(){
  
  function getRootLevelDomain(a, b, c) {
    
    a = a || "" + location.hostname;
    b = a.split(".");
    c = (/\.co\.|\.com\.|\.org\.|\.edu\.|\.net\.|\.asn\./.test(a)) ? 3 : 2;
    return b.splice(b.length - c, c).join(".");
  }

  
  function setCookie(cname, cvalue, exdays) {
    const domain = getRootLevelDomain()
    
    let d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();

    let cookieContent
    try{
      if(location.protocol != 'http:'){

        cookieContent = ""+cname + "=" + cvalue 
                  + ";domain="+domain
                  + ";SameSite=Strict"
                  + ";secure"
                  +";path=/;"+expires+";";
      }
      else{
        cookieContent = ""+cname + "=" + cvalue 
                  + ";domain="+domain
                  +";path=/;"+expires+";";
      }
      console.log(cookieContent)
      document.cookie = cookieContent
    }
    catch(e){
      console.log(e)
    }
    
  }

  

  function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
          c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  const cookieBanner = document.querySelector( '.cookiebanner' );
  if (cookieBanner != null) {  

    if( getCookie('Hide Cookie Banner') == '' ){
      cookieBanner.style.display = 'block'
    }

    const cookieBannerClose = document.querySelector( '.cookiebanner .close-banner' );
    cookieBannerClose.addEventListener('click', function(){
      cookieBanner.classList.add('hide')

      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      
      // Cookie Vars
      const cookieConsentTimeStamp = mm + '-' + dd + '-' + yyyy;
      
      setCookie('Hide Cookie Banner', cookieConsentTimeStamp , 365, location.hostname);
    });
   
  }
}