export function abTest(ocw_experiment){
    
  let experimentComps = document.querySelectorAll('[data-component]');
  let activeComponents = [];
  var currLang = document.documentElement.lang;
  if(!experimentComps){
    showControl();
    return;
  }
      
  if(Object.keys(ocw_experiment).length){
    for(const singleExperiment in ocw_experiment ) {
      
      experimentComps.forEach(elem => {
        if( ocw_experiment[singleExperiment].payload ){

            if(typeof(ocw_experiment[singleExperiment].payload.component) == 'string'){
              if( ocw_experiment[singleExperiment].payload.component == elem.dataset.component){
                if(ocw_experiment[singleExperiment].key == elem.dataset.abtesttype){
                  activeComponents.push(ocw_experiment[singleExperiment].payload.component);
                  if(elem.dataset.abtesttype == 'control'){
                    elem.style.visibility = null;
                  }
                  else {
                    document.querySelectorAll('[data-component="'+ocw_experiment[singleExperiment].payload.component+'"][data-abtesttype="control"]').forEach(elem =>{
                      elem.style.display='none';
                      elem.style.visibility = null; 
                    });
                    elem.style.display = 'block';
                  }
                }
              }
            }
            else {
              if( typeof(ocw_experiment[singleExperiment].payload.component) !== 'undefined' ){
                ocw_experiment[singleExperiment].payload.component.forEach((element,key) => {
                
                  if( ocw_experiment[singleExperiment].payload.component[key] == elem.dataset.component){
                    if(ocw_experiment[singleExperiment].key == elem.dataset.abtesttype){
                      activeComponents.push(ocw_experiment[singleExperiment].payload.component[key]);
                      if(elem.dataset.abtesttype == 'control'){
                        elem.style.visibility = null;
                      }
                      else {
                        document.querySelectorAll('[data-component="'+ocw_experiment[singleExperiment].payload.component[key]+'"][data-abtesttype="control"]').forEach(elem =>{
                          elem.style.display='none';
                          elem.style.visibility = null; 
                        });
                        elem.style.display = 'block';
                      }
                    }
                  }
                  else {
                    if( ocw_experiment[singleExperiment].payload.component[key] == 'global-replace'){
                      if(ocw_experiment[singleExperiment].key != 'control'){
                        document.querySelectorAll(ocw_experiment[singleExperiment].payload[key].toReplace).forEach(elem =>{
                          if(currLang == 'en-US'){
                            elem.innerHTML = ocw_experiment[singleExperiment].payload[key].replaceWith;
                          }
                          else { //es-ES
                            elem.innerHTML = ocw_experiment[singleExperiment].payload[key].replaceWithES;
                          }
                        });
                      }
                    }
                  }
                });
              }

            }
            if( ocw_experiment[singleExperiment].payload.component == elem.dataset.component){
              if(ocw_experiment[singleExperiment].key == elem.dataset.abtesttype){
                activeComponents.push(ocw_experiment[singleExperiment].payload.component);
                if(elem.dataset.abtesttype == 'control'){
                  elem.style.visibility = null;
                }
                else {
                  document.querySelectorAll('[data-component="'+ocw_experiment[singleExperiment].payload.component+'"][data-abtesttype="control"]').forEach(elem =>{
                    elem.style.display='none';
                    elem.style.visibility = null; 
                  });
                  elem.style.display = 'block';
                }
              }
            }
            else {
              if( ocw_experiment[singleExperiment].payload.component == 'global-replace'){
                if( ocw_experiment[singleExperiment].payload.component == 'global-replace'){
                  if(ocw_experiment[singleExperiment].key != 'control'){
                    document.querySelectorAll(ocw_experiment[singleExperiment].payload.toReplace).forEach(elem =>{
                      if(currLang == 'en-US'){
                        elem.innerHTML = ocw_experiment[singleExperiment].payload.replaceWith;
                      }
                      else { //es-ES
                        elem.innerHTML = ocw_experiment[singleExperiment].payload.replaceWithES;
                      }
                    });
                  }
                }
              }
            }
          

        }
      
      });
    }
    console.log('experiments active: '+activeComponents.length);
    showControl(activeComponents);
  }
  else {
    showControl();
  }
  
}

export function showControl(compsList = []){

document.querySelectorAll('[data-abtesttype="control"]').forEach(elem => {
  if(!compsList.includes(elem.dataset.component) ){
    elem.style.visibility = null;
  }
});
}
