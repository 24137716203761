export function highlightText(){
    const goToStack = document.querySelectorAll(".go-to");
	if (goToStack.length > 0) {
		goToStack.forEach((elem) => {
			elem.addEventListener("click", function () {
				const goToId = elem.getAttribute("href");
				const toHighlight = document.querySelector(goToId);
				toHighlight.style.transition = 'background-color 1s ease-out';
                toHighlight.style.backgroundColor = '#6CDB8B';
                setTimeout(() => {
                    toHighlight.style.transition = 'background-color 1s ease-out';
                    toHighlight.style.backgroundColor = "transparent";
                }, "1000");
                
			});
		});
	}
}
