export function personalizationHeroBanner(){
    
    const dle = JSON.parse(localStorage.getItem("tealium_va_oportun_main") || "{}");
    if(document.querySelector('body.home')){
        if(dle && dle.badges && dle.badges[30630]){
            //Show test hero by removing control hero
            if (document.querySelector('.Hero.Default')){         
                document.querySelector('.Hero.Default').remove();
            }
            if (document.querySelector('.Hero.CC') ) {
                document.querySelector('.Hero.CC').remove();
            }
        } else if (dle && dle.badges && dle.badges[30632]) {
            if (document.querySelector('.Hero.Default')) {
                document.querySelector('.Hero.Default').remove();
            }
            if (document.querySelector('.Hero.PL') ){
                document.querySelector('.Hero.PL').remove();
            }
        }else{
            //Show control hero by removing test hero
            if (document.querySelector('.Hero.PL') ) {
                document.querySelector('.Hero.PL').remove();
            }
            if (document.querySelector('.Hero.CC') ){
                document.querySelector('.Hero.CC').remove();
            }
        }
    }
}