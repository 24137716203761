export function accordion() {
  const triggers = document.querySelectorAll('.accordion__trigger');

  function toggle(e) {
    const { currentTarget } = e;
    const root = currentTarget.parentNode;
    if (!root.classList.contains('collapsed')) {
      root.classList.add('collapsed');
    } else {
      root.classList.remove('collapsed');
    }
  }

  for (const trigger of triggers) {
    trigger.addEventListener('click', toggle);
    const root = trigger.parentNode;
    root.classList.add('collapsed');
  }
}
