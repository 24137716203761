export function externalLinks() {
  const links = document.querySelectorAll(
    'a[href]:not([href^="#"]):not([href^="tel:"]):not([href^="sms:"]):not([href^="mailto:"]):not([href^="/"])',
  )
  const allowed = [location.hostname, 'oportun.com', 'oportundev3'];

  function isInternal(url) {
    return allowed.some((str) => url.hostname.includes(str));
  }

  for (const link of links) {
    try {
      const url = new URL(link.href);

      if (isInternal(url)) {
        continue;
      }

      link.classList.add('external');

      link.insertAdjacentHTML(
        'beforeend',
        '<span class="ext-ico"><oportun-icon name="external-link"></oportun-icon></span>',
      );
    } catch (e) {
      console.error(e);
    }
  }
}
