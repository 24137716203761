
export function loadMore(){
    const loadmore = document.querySelector('#loadmore');
    if (loadmore == null) {
        
        return;
    }

    loadmore.addEventListener('click',function(){
        
        const loadmorecont = document.querySelector('.loadmore-container')

        const query = this.dataset.query;
        const page = parseInt(this.dataset.page);
        const max = parseInt(this.dataset.max);
        const template = this.dataset.template;
        const year = ( ( this.dataset.year === undefined ) ?  null : this.dataset.year  );
        const reset = ( ( this.dataset.reset === undefined ) ?  false : this.dataset.reset  );
        
        const datatoquery = new FormData();
        
        const ajaxCall = window.location.protocol+'//'+window.location.hostname+'/wp-admin/admin-ajax.php';

        datatoquery.append( 'action', "load_more_posts")
        datatoquery.append( 'query', query)
        datatoquery.append( 'page', page)
        datatoquery.append( 'template', template)
        datatoquery.append( 'year', year)
        datatoquery.append( 'reset', reset)

        let promiseFetch = fetchMoreNews(ajaxCall, datatoquery)
        
        promiseFetch.then((response) => {
            response = JSON.parse(response)
            
            if (response.status !== "error") {

                const posts = response.posts
                this.dataset.page = response.page
                
                if ( (response.reset !== 'undefined') &&  (response.reset !== 'null') ) {
                    [].forEach.call(document.querySelectorAll('.' + template),function(e){
                        e.parentNode.removeChild(e);
                    });
                    
                }
                loadmorecont.innerHTML+= (posts)
                
                if(response.page >= max){
                    this.style.display = 'none';
                }
            }
            else{
                if(response.message == 'No More Posts'){
                    this.style.display = 'none';
                }
            }
            console.log(response)
        })
    
    });

    const fetchMoreNews = function (url,params){

        return new Promise( function(resolve, reject) {
            fetch( url 
                , { method: 'POST', body: params } )
            .then(
                response => response.text()
            )
            .then( res => {
                const APIData = (res);
                resolve(APIData);
            })
            .catch( error =>{
                res = JSON.parse(error);
                
            });
        });
    }

}

export function LoadMoreYears(){
    const filterYear = document.querySelector( '#filter-year' );
    if (filterYear == null) {
        
        return;
    }
    
    filterYear.addEventListener('change', function () {

        const selectedOption = filterYear.options[filterYear.selectedIndex].text;
        
        var selectedOptionText = selectedOption.toLowerCase();
        const loadmore = document.querySelector('#loadmore');

        loadmore.dataset.reset = true ;
        if ( selectedOptionText === 'any' ) {
            loadmore.dataset.year = null;
        } else {
            loadmore.dataset.year = selectedOptionText ;
        }

        loadmore.dataset.page = 0;
        
        loadmore.click();

        setTimeout( function () { loadmore.dataset.reset = null ; }, 1000 );
    });
}

export function LoadMoreReviews(){

    const loadMoreReviews = document.querySelector('#locationloadmorereview');
    if( loadMoreReviews == null ){
        return;
    }
    let end = 6

    loadMoreReviews.addEventListener('click', function (e) {
        e.preventDefault()
        let size_li = document.querySelectorAll("#reviews .review-item")
        end = end + 6 <= size_li.length ? end + 6 : size_li.length;
        
        for (let index = (end - 6) ; index < end; index++) {
            
            if(size_li[index] == null ){
                return
            }
            size_li[index].style.display = 'block'
        }
        if (size_li.length == end) {
			loadMoreReviews.style.display = 'none';
		}
    });
}