export function personalizedCTABanner(){
    const ocw_tvom =  JSON.parse(localStorage.tealium_va_oportun_main || "{}");
    const cta_banner   = document.querySelector('.banner-content');
    const banners = document.querySelectorAll('.banner-content-personalized');
    if(!banners){
        return;
    }
    window.addEventListener('load',function(){
        banners.forEach(elem => {
        
            const condition = elem.dataset.state;
    
            if(utag_data.visitor_state_location == condition){
                cta_banner.style.display= 'none';
                elem.style.display = 'block'; 
            }
    
        });
    })
    

}